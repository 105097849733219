.jumbotron-area{
    margin-bottom: 0;
}
.jumbotron-area p{
    font-size: 1.3rem;
}



.area-tab{

    position: relative;
    margin-top: 0;
}

.area-tab-content{
    position: relative;
    margin: 20px;
}

.area-tab .nav-tabs{
    border:0;
    position: absolute;
    width:100%;
    text-align: center;
    justify-content: center;
    top: -1rem;
}

.area-tab-text{

    width: 100%;
    padding-bottom:65px;
}

.area-tab-text.short-text{
    height: 188px;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
}

.area-tab .tab-content>.tab-pane{
    margin-bottom: 10px;
    background-color: #F4F4F4;
}



.area-tab h5 a:hover{
    text-decoration: none;
}


.area-tab-content .btn-show-more{

    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.area-tab-text.short-text:after {
    content  : "";
    position : absolute;
    z-index  : 1;
    bottom: 0;
    left     : 0;
    pointer-events   : none;
    background-image : linear-gradient(to bottom,
                      rgba(244,244,244, 0),
                      rgba(244,244,244, 1) 90%);
    width    : 100%;
    height   : 4em;
  }

  .area-tab-text p{
      text-align: justify;
      /*padding-bottom:5rem;*/
  }

  .area-tab-buttons{
    padding-left: 10px;

    width: 100%;
    bottom: 0;
    padding-right: 10px;
  }
  .area-tab-buttons .col-md-3{
        padding:0 5px;
  }
  .area-tab-buttons a.btn{
    width: 100%;
    white-space: normal;
    margin: 0;
    padding: .5rem;
    height: 60px;
    font-size: 1.1rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .area-tab .tab-content>.tab-pane.fade{
    opacity: 100;
  }


  @media (min-width:768px){
    .area-tab{
        background-color: #F4F4F4;
    }

    .area-tab-content{
        margin:0;
    }

    .area-tab-buttons{
        position: absolute;
    }

    .area-tab-content .btn-show-more{

        bottom: 60px;
        position: absolute;
    width: 25%;
    right: 15px;

    }

    .area-tab-content .btn-show-more{

        bottom: 40px;
        top:auto;

    }

    .area-tab-buttons a.btn{
        margin-bottom: 0;
    }

    .area-tab .tab-content>.tab-pane.fade:not(.show){
        opacity: 0;
      }
}




/* REACTOR */

.mini-timeline{
	display: flex;

    align-items: stretch;
    width: 100%;
  height: .7rem;
  margin-left: -2px;
    margin-right: -2px;
}
.mini-timeline-item{
	flex: 1;
	background:#ccc;
	margin:0 2px;
}

.reactor-tabs{
	position: initial;
    background: #eee;
    display: flex;
}
.reactor-tab-content>.tab-pane:not(.active){
    display: none !important;
}

ul.reactor-tabs li{
	flex:1;
	font-size: 14px;
	margin-left: 0;
    margin-right: 0;
}

 ul.reactor-tabs li a.nav-link{
	background: none;
	transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    width: 100%;
}
@media(min-width:992px){
    .reactor-tab-content{
        height: 666px;
    }
}
.reactor-tab-content img{
	width:100%;
}

ul.reactor-tabs li a:hover, ul.reactor-tabs li a.nav-link.active {
    color: #fff;
    background: #47A5DC;
}


.reactor-tab-content .tab-pane{
	    height: 100%;
    	background: #eee;
}

.reactor-tab-content-text{

    padding: 1.2rem;
}

.timeline-reactor{

	margin-left: -4px;
    margin-right: -1px;
    flex-direction: row;
    flex-wrap: wrap;
}



.timeline-reactor-item{
	width: auto;
    height: 170px;

    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
}

@media(min-width:576px){
    .timeline-reactor{
        display: flex;
    }

    .timeline-reactor-item{
        width: 33.3%;
    }
}



.timeline-reactor-item-header{
	    color: #fff;
    background-color: #47A5DC;
    text-align: right;
    height: 37px;
    padding: .5rem 1rem;
    display: block;
    font-size: 14px;
    font-weight: 700;

}

.timeline-reactor-item-body{
    padding: 1rem;
    background: #eee;
    height: 132px;
}

.timeline-reactor-item-final{

	    font-size: 34px;
    color: #fff;
    font-weight: 700;
    line-height: 36px;
}

.timeline-reactor-item-final .timeline-reactor-item-body{
	background-color:#47A5DC;
	height: 100%;
}


/* ROTATING CARDS */

/* Card styles for rotation */
.rotate-container {
    position: relative;
  }
  .rotate-container .card-front, .rotate-container .card-back {
    width: 100%;
    height: 100%;
    -webkit-transform: perspective(600px) rotateY(0deg);
            transform: perspective(600px) rotateY(0deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all 0.5s linear 0s;
  }

  .rotate-container .card-back {
    -webkit-transform: perspective(1600px) rotateY(180deg);
            transform: perspective(1600px) rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .card:hover .card-front{
    -webkit-transform: perspective(1600px) rotateY(-180deg);
            transform: perspective(1600px) rotateY(-180deg);
  }

  .card:hover .card-back{
    -webkit-transform: perspective(1600px) rotateY(0deg);
    transform: perspective(1600px) rotateY(0deg);
  }


  .rotate-container .rotate-card-front {
    -webkit-transform: perspective(1600px) rotateY(-180deg);
            transform: perspective(1600px) rotateY(-180deg);
  }
  .rotate-container .rotate-card-back {
    -webkit-transform: perspective(1600px) rotateY(0deg);
            transform: perspective(1600px) rotateY(0deg);
  }

  /* Modified card styles */
  .startup.card {
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
  }

  .startup.card{
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .card-front{
    border: 0;
    box-shadow: none;
  }
  .card .card-header p {
    margin: 0;
  }

  .card-back p{
      font-size: 14px;
      margin-top: 10px;
  }

  .card-website{
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
  }



  /* HUMANITIES */

  ul.humanities-tabs li a, ul.humanities-tabs li a.nav-link {
    text-align: center;
    font-size: 18px;
}

  ul.humanities-tabs li a:hover, ul.humanities-tabs li a.nav-link.active {
    background: #FCC924;
}
.humanities-tab-content{
    height: auto;
}

.humanities-tab-content .tab-pane{
    height: auto;

}

.humanities-tab-content .reactor-tab-content-text{
    padding:0;
}
