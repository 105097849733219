
/*CAROUSEL*/
.carousel {
    height: 400px;
}
.carousel-item img {
    height: 400px;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
}
.carousel-indicators li {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #6B6B6B;
}
.carousel-indicators li.active {
    background-color: #D21E47;
}

.event-breadcrumb__items span.active {
    color: #fff !important;
}