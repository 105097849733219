

/*
FONTS
*/

@import url('https://fonts.googleapis.com/css?family=Asap:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Asap+Condensed:400,500,600,700');



/*
LAYOUT
*/
body{
    font-family: 'Asap', sans-serif;
    color:#4A4A4A;

}

h1{
    font-family: 'Asap';
    font-weight: 600;
    font-size: 2.25rem;
    color: #030404;

}
h2{
    font-family: 'Asap Condensed';
    font-weight: 500;
    color:#030404;
    font-size: 1.75rem;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
}
h3{
    font-family: 'Asap Condensed', sans-serif;
    color:#D21E47;
}

h4{
    font-family: 'Asap', sans-serif;
}

section{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

a{
    color:#D21E47;
}

a:hover{
    color:#D21E47;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
    outline-color: transparent;
    outline-style: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-shadow: none;
}

.page-title {
    margin: 0;
    height: 97px;
    line-height: 97px;
}

.page-title-multiplerows {
    margin: 0;
    height: 97px;
    padding-top: 26px;
}



.form-control{
    border-radius: 0;
}

.section-grey{
    background-color:#F9F9F9;
}


/*
COLORS
*/

.color-black{
    color:#030404 !important;
}
.color-base{
    color:#D21E47 !important;
}
.color-arte{
    color:#FCC924 !important;
}

.color-scuola{
    color:#E82A93 !important;
}

.color-impresa{
    color:#409A46 !important;
}

.color-ricerca{
    color:#47A5DC !important;
}

.background-arte{
    background-color:#FCC924 !important;
}

.background-scuola{
    background-color:#E82A93 !important;
}

.background-impresa{
    background-color:#409A46 !important;
}

.background-ricerca{
    background-color:#47A5DC !important;
}

.background-arte-light{
    background-color:rgba(252,201,36,.20) !important;
}

.background-scuola-light{
    background-color:rgba(232,42,147,.15) !important;
}

.background-impresa-light{
    background-color:rgba(64,154,70,.15) !important;
}

.background-ricerca-light{
    background-color:rgba(71,165,220,.15) !important;
}

.p-scuola b{
    color:#E82A93;
}


/*
HEADER
*/

header #navbarNav .navbar-nav{

}

header .navbar-light .navbar-nav > li
{
    padding: 0;
    margin: 0;

    vertical-align: bottom;
}
.dropdown-item.active, .dropdown-item:active{
    background-color:#D21E47;
}
header .dropdown-toggle::after{
    color:#D21E47;
}
header .navbar-light .navbar-nav.navbar-main .nav-item{
    position: relative;
    width:100%;
    text-align: center;
}
header .navbar-light .navbar-nav.navbar-main .nav-item>a{
    text-transform: uppercase;
    font-size: 18px;
    line-height: 18px;
    color: #555555;
    padding: 1.2rem;
    margin:0;
    border-top: 2px solid #555;
    position: relative;
    font-family: 'Asap Condensed';
}

header .navbar-light .navbar-nav.navbar-main.main-navbar-en .nav-item>a{
    text-transform: none;
}

.navbar-collapse{
    position: relative;
}

header .navbar-light .navbar-nav.navbar-main .nav-item:first-child>a{
    border-top:0;
}


header .navbar-light .navbar-nav.navbar-main .nav-item.active>a{
    color:#D21E47;
    font-weight: 500;
    position: relative;

}

header .dropdown-menu{
    text-align: center;
    background: transparent;
    border: transparent;
}



header .dropdown-toggle:after{
    display: none;
}




header .navbar-light .dropdown-toggle::after{
    border:0;
}



header .nav-secondary{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: inline-block;
    margin-top: 1.4rem;
}
header .nav-secondary .nav-item{
    display: inline-block;
    padding: 1rem 0;
}




header .nav-secondary .nav-item a{
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
    padding: 0;
    color: #555555;
}

header .nav-secondary .nav-item a.nav-margin-right{
    border-right: 1px solid #555555;
    padding-right: 0.7rem;
}



header .dropdown{
    position: inherit;

   }

header .dropdown-item{
    font-size: .8rem;
   }

header .dropdown h5{
       text-transform: uppercase;

       font-size: 1.1rem;
    margin-top: 1rem;
    white-space: normal;

   }

header .navbar-light .navbar-nav.navbar-main .nav-item>a[aria-expanded='true']:after{
    content: " ";
    height: 1px;
    width: 100px;
    background: #4A4A4A;
    position: absolute;
    bottom: 0px;
    left:calc(50% - 50px);
    display: block;
    color: #4A4A4A;
    margin-left: 0;
    border:0;

}

.locale-menu-lg{
    text-align: center;
    margin-top: 30px;
}

.locale-menu-lg a{
    color:#555555;
}

.locale-menu-lg a.current-locale{
    color:#D21E47;
}

.newsletter-header{ position: relative;    height: 36px;
    width: 145px;

    display: block;
    margin: 30px auto;
   }

   .newsletter-header img{
    height:36px;
}

.newsletter-header span,.newsletter-header span:hover{
    position: absolute;
    color:#fff;
    text-decoration: none;
    top: 10px;
left: 46px;
font-size: 12px;
}



@media (min-width:992px){
    header .dropdown-menu{
        top: 95%;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.15);
    }
    header .dropdown-menu-aree{
        width: 800px;
        left: calc(57% - 400px);
        border-radius: 0;

        }

    header .navbar-light .navbar-nav.navbar-main .nav-item{
        text-align: inherit;
        width: auto;
    }

    header .navbar-light .navbar-nav.navbar-main .nav-item.active>a:after{
        content: " ";
        height: 3px;
        width: 100%;
        background: #4A4A4A;
        position: absolute;
        bottom: -34px;
        left:0;
        display: block;
        color: #4A4A4A;
        margin-left: 0;
        border:0;

    }

    header .navbar-light .navbar-nav.navbar-main .nav-item.active:after{
        background-color: #030404;
        height: 2px;
        width: 100%;
        position: absolute;
        display: block;
    }

    header .navbar-light .navbar-nav.navbar-main .nav-item>a{
        margin: 1.9rem .5rem 1rem;
        padding: 0;
        border-top:0;
    }

    header .dropdown:hover>.dropdown-menu {
        display: block;
       }

    header .dropdown h5{
        font-size: .8rem;
        margin-top: 0rem;
    }

    header .navbar-light .navbar-nav.navbar-main .nav-item>a[aria-expanded='true']:after{
        content:none;
    }



    .locale-menu-lg{
        /*position: absolute;*/
        right: 0;
        display: block;
        margin-top: 13px;
        text-align: right;
        margin-left: 40px;
    }

    .newsletter-header{
        margin-top: 42px;
       }


}


/*
BUTTONS
*/

.btn{
    padding: .3rem 2rem;
    font-family: 'Asap Condensed';
    font-weight: 600;
    font-size: 1.25rem;
    border-radius:0;
    line-height: 2rem;
    text-transform: uppercase;
}

.btn-disabled{
    background-color: #9B9B9B;
    border-color: #9B9B9B;
    color:#fff;
    cursor: not-allowed !important;
    pointer-events: none;
}

.btn-primary{
    background-color: #D21E47;
    border-color: #D21E47;
    color:#fff;
}




.btn.btn-primary,a.btn.btn-primary{
    color:#fff;
}



.btn-outline-primary,a.btn.btn-outline-primary{
    border-color: #D21E47;
    color:#D21E47;
}





a.btn.btn-arte{
    background-color:#FCC924;
    border-color: #FCC924;
}

.btn.btn-arte:hover,a.btn.btn-arte:hover{
    background-color: #fff;
    border-color: #FCC924;
    color:#FCC924;
}

a.btn.btn-scuola{
    background-color:#E82A93;
    border-color: #E82A93;
}

.btn.btn-scuola:hover,a.btn.btn-scuola:hover{
    background-color: #fff;
    border-color: #E82A93;
    color:#E82A93;
}

.btn.btn-impresa{
    background-color:#409A46;
    border-color:#409A46;
}

.btn.btn-impresa:hover,.btn.btn-impresa:hover{
    background-color: #fff;
    border-color: #409A46;
    color:#409A46;
}

a.btn.btn-ricerca{
    background-color:#47A5DC;
    border-color:#47A5DC;
}

a.btn.btn-outline-primary.btn-scuola{
    color:#E82A93;
    border-color: #E82A93;
    background: #fff;
}

a.btn.btn-outline-primary.btn-arte{
    color:#FCC924;
    border-color: #FCC924;
    background: #fff;
}

a.btn.btn-outline-primary.btn-impresa{
    color:#409A46;
    border-color: #409A46;
    background: #fff;
}

a.btn.btn-outline-primary.btn-ricerca{
    color:#47A5DC;
    border-color: #47A5DC;
    background: #fff;
}

.btn.btn-primary:hover,a.btn.btn-primary:hover,.btn.btn-primary:active,a.btn.btn-primary:active{
    background-color: #fff;
    border-color: #D21E47;
    color:#D21E47;
}

.btn.btn-outline-primary:hover,a.btn.btn-outline-primary:hover{
    background-color: #D21E47;
    border-color: #D21E47;
    color:#fff;
}

/*radio button*/
/* The container */
.radio-button-container {
    position: relative;
    padding-left: 24px;
    padding-right: 16px;
    margin: auto;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-button-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio-button-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .radio-button-checkmark {
    background-color: #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-button-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .radio-button-checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .radio-button-checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}

/*
TABS
*/
.tab-content>.tab-pane{
    background: none;
    border: none;
}


ul.nav-tabs li {

    margin: 0 .3rem;
    display: inline-block;
    padding-right: 0;

}
ul.nav-tabs li a.nav-link {
    display: inline-block;
    text-decoration:none;
    padding: .5rem 2rem;
    border-radius:3px;
    transform: skew(10deg);
    -o-transform: skew(10deg);
    -moz-transform: skew(10deg);
    -webkit-transform: skew(10deg);
    color:#030404;
    background: #fff;
    border-radius: 0;
    border:0;
    text-transform: uppercase;
}
ul.nav-tabs li a span {
    display: inline-block;
    transform: skew(-10deg);
    -o-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
}

ul.nav-tabs.scuola-tabs li a{
    color:#E82A93;
}

ul.nav-tabs.arte-tabs li a{
    color:#FCC924;
}

ul.nav-tabs li a:hover,ul.nav-tabs li a.nav-link.active {
    color:#fff;
    background:#D21E47;
}



ul.nav-tabs.scuola-tabs li a:hover,ul.nav-tabs.scuola-tabs li a.active {
    color:#fff;
    background:#E82A93;
}


ul.nav-tabs.arte-tabs li a:hover,ul.nav-tabs.arte-tabs li a.active {
    background:#FCC924;
}

ul.nav-tabs.impresa-tabs li a:hover,ul.nav-tabs.impresa-tabs li a.active {
    background:#409A46;
}

ul.nav-tabs.ricerca-tabs li a:hover,ul.nav-tabs.ricerca-tabs li a.active {
    background:#47A5DC;
}

.nav-tabs {
    display:none;
}

.card-header{
    background-color: #f4f4f4;
}

@media(min-width:768px) {
    .nav-tabs {
        display: flex;
    }

    .tab-content .card {
        border: none;
    }

    .tab-content .card .card-header {
        display:none;
    }

    .tab-content .card .collapse{
        display:block;
    }
}

@media(max-width:767px){
    #area-tabs .tab-pane {
        display: block !important;
        opacity: 1;
    }
}

/*
INFOBOX
*/

.infobox h3 {
    position: relative;
    margin-bottom: 2rem;
}
.infobox-short-text{
    height: auto;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
    /*text-align: justify;*/
    padding-bottom: 30px;
}
.infobox-claim p{
    font-size: 1.4rem;
}

.infobox-short-text.short-text{
    height: 115px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.infobox-short-text.short-text:after {
    content  : "";
    position : absolute;
    z-index  : 1;
    top: 65px;
    left     : 0;
    pointer-events   : none;
    background-image : linear-gradient(to bottom,
                      rgba(244,244,244, 0),
                      rgba(244,244,244, 1) 90%);
    width    : 100%;
    height   : 4em;
  }



/*
MODAL
*/

.modal-content{
    border-radius: 0;
}

.modal-header h5{
    color:#D21E47;
}

.modal-body{
    background: #F4F4F4;
}


/*
VIDEO COVER / MODAL VIDEO
*/

.video-cover{
    position: relative;
    display: block;
}

.video-cover:after {
    border-bottom: 151px solid #D8D8D8;
    border-left: 265px solid transparent;
    border-right: 1px solid transparent;
    position: absolute;
    /*content: " ";*/
    display: block;
    bottom: -5px;
    right: -5px;
  }

  .video-button-image{

    position: absolute;
    right: calc(50% - 40px);
    bottom: calc(50% - 27px);
    z-index: 2;
    width: 80px;
    height: 54px;
    background: #D21E47;
    border-radius: 9px;
    background-image: none !important;
    cursor: pointer;
    box-shadow: 5px 5px 10px #333;
  }

  .video-button-image:hover{

  }

  .video-button-image:after{
    width: 19px;
    height: 15px;
    border-style: solid;
    border-width: 13px 0 13px 25px;
    border-color: transparent transparent transparent #ffffff;
    content: "";
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 13px);
  }

.modal-video .modal-dialog {
    max-width: 800px;
    margin: 30px auto;
}

.modal-video .modal-body {
    position:relative;
    padding:0px;
}

.modal-video .close {
    position:absolute;
    right:-30px;
    top:0;
    z-index:999;
    font-size:2rem;
    font-weight: normal;
    color:#fff;
    opacity:1;
}



/**
PAGE BOX
*/

.page-box{
    position:relative;
}
.page-box p{
    font-size: .8rem;
}
.page-box h2{
    /*font-family: 'Asap Condensed';*/
    margin-top: 0;

}

.page-box small{
    text-transform: uppercase;
    font-family: 'Asap Condensed';
    font-weight: 700;
    color:#000;
}

.bcolor-box ul, .page-box ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  .bcolor-box li, .page-box li {
    padding-left: 16px;
    text-indent: -14px;
    padding-top: 10px;
    padding-bottom: 10px;

  }

  .bcolor-box-impresa li, .page-box.page-box-impresa li{
    border-bottom: 1px #409A46 solid;
  }

  .bcolor-box li:last-child, .page-box li:last-child{
      border:none;
  }

  .bcolor-box li:before, .page-box li:before {
    content: "»";
    padding-right: 5px;

  }

  .bcolor-box-impresa li:before, .page-box.page-box-impresa li:before{
      color:#409A46;
  }

.page-box:before {
    border-top: 60px solid #D8D8D8;
    border-left: 105px solid transparent;
    border-right: 1px solid transparent;
    position: absolute;
    content: " ";
    display: block;
    top: -3px;
    right: 10px;
  }

  .page-box.page-box-impresa:before{
    border-top-color: #409A46;
  }

  .page-box-icon{
    position: absolute;
    width: 40px;
    height: 36px;
    background-size: 100%;
    right: 18px;
    top: 0px;
    z-index: 2;
  }
  .bcolor-box{
    margin-left: 5px;
    margin-right: 5px;
    position:relative;
    background-color: #f4f4f4;
  }

  .bcolor-box h2{
      margin-top: 0;
  }

  .bcolor-box p{
      margin-bottom:0;
  }

  .bcolor-box:before{
    position: absolute;
    content: "";
    width: 382px;
    height: 58px;
    top: -50px;
    right: 0;
    display: none;
}


@media(min-width:767px){
    .bcolor-box:before {
        display: block;

    }
}


/*
FOOTER
*/

footer{
    padding: 3rem 0;
    background: #F4F4F4;
}

footer ul{
    padding-left: 0;
    list-style: none;
}

footer a{
    font-family: 'Asap Condensed';
}

footer label{
    font-family: 'Asap Condensed';
}

footer li a{
    color:#6B6B6B;
    font-family: 'Asap Condensed';
}

footer a:hover{
    color:#6B6B6B;
}
footer h4{
    font-size:1rem;
    color:#D21E47;
    margin-bottom: 2rem;
    font-family:'Asap Condensed';
}

footer p{
    font-family: 'Asap Condensed';
}

footer .donate-image{
    height: 20px;
    margin-right: 1rem;
    margin-top: .5rem;
}



/* COOKIE CONSENT */

.cookie-consent{
    position: fixed;
    bottom: 0;
    z-index: 999;
    background: rgba(0,0,0,.7);
    color: #fff;
    padding: 30px;
    right: 0;
    left: 0;
}


/* NEWSLETTER MODAL */

.newsletterModal.modal{
    left:auto;
    top: auto;
}

.newsletterModal .modal-dialog
{
    box-shadow: 5px 5px 10px #666;
    max-width: 320px;
}


/* HEADER NEWS/EVENTS */

.ne-banner{ color:#fff;
background-size:cover !important; margin:0;}
.ne-banner h1{
    color:#fff;
    font-weight:300;
}
.ne-banner .container{ padding-top:5rem; padding-bottom:5rem;}
