.jumbotron-chisiamo.bg-cover{
    background-color: #fff;
    background-position: center bottom;
    padding-bottom: 6rem;
}

.jumbotron-chisiamo p{
    color:#6B6B6B;
    font-size: 1.2rem;
    /*text-align: justify;*/
}
.signature-container{
    float: right;
}
.signature{

    font-size: 1.6rem;
}
.membro-consiglio{
    background-color: #F4F4F4;
}

.staff-member{
    margin-bottom: 2rem;
}

.staff-member h5{
    font-size: 1.1rem;
}

.staff-member p{
    font-size: .7rem;
}

.staff-member a {
    position: absolute;
    bottom: 3px;
    font-size: .7rem;
}

.staff-member .staff-img{
    width:120px;
    margin-right:1rem;
}

.more-staff{
    display:none;
}

.partner{
    background-color:#F9F9F9;
    padding: 2px;
}
.partner img{
    width:100%;
}

.more-partner{
    display:none;
}

.map-responsive{
    overflow:hidden;
    padding-bottom: 30%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}


/* MARINO GOLINELLI */


.mg-text,.mg-date{
    position:absolute;
    z-index: 10;
}

h1{
    font-size:2.3rem;
    position: relative;
    z-index: 4;
}

@media screen and (min-width:960px){
    h1{
    font-size:3.5rem;

}
}

img{
    max-width:95vw;
}

.edge{
    width: 100%;
    height: 25px;
    position: absolute;

    z-index: 1;
}
.golinelli-section{
    position:relative;
}

/*
SECTION 1
*/

.golinelli-section-1{
    height:350px;
    background-color:#F7B0D8;
    position:relative;
    margin-top:0;
}

.edge-bottom-1{
    bottom: 0px;
}

.golinelli-main-figure{
    position: absolute;

    z-index:2;
    right: 10px;
        bottom: -112px;
        width: 310px;
}
.golinelli-main-mobile{
    position: absolute;
    top: 6rem;
    margin-left: 1rem;
    z-index: 2;
}
.golinelli-section-1 h1{
    margin-top: 2rem;
    font-size: 2.1rem;
    margin-left: 2rem;
}
.timeline-1{
    position:relative;
    z-index:3;
    width:500px;
}
.timeline-1-mobile{
    margin-top: 0px;
    z-index: 4;
    width: 360px;
margin-left: auto;
margin-right: auto;
max-width: 400px;
margin-bottom: 60px;
}


.role-list{
    color: #fff;
list-style-type: none;
position: relative;
margin-left: 2rem;
margin-top: 5rem;
z-index:4;
}
img.main-design{
    position: absolute;
    left: 0;
    top: 40px;
}
img.signature{
    margin-left: 1rem;
}

.video-story-1{
    width: 80%;
margin: auto;
position: relative;
}



@media screen and (min-width:960px){

     .golinelli-section-1{
        height:650px;

    }

    .golinelli-main-figure {

       left: 65%;
       right:auto;
    top: 155px;
    width: 378px;

    }


    .golinelli-section-1 h1{
        margin-top: 11rem;
        font-size: 3.3rem;
        margin-left: 3rem;
    }

    .role-list{
        margin-left: 5rem;
        margin-top: 1rem;
        z-index:4;
    }

    .timeline-1{
        margin-top:3rem;
    }

}

/*
SECTION 2
*/

.golinelli-section-2{
    padding-top:0rem;
    padding-bottom:5rem;
}

img.video-play-button{
    position: absolute;
    bottom: 30px;
    right: 30px;
}
.golinelli-section-3{
    background-color:#7ee1c6;

}

 .edge-top-2{
    height: 23px;
    top: -10px;
}

.edge-bottom-2{
    bottom: -12px;
}

.video-story-2{
    width: 80%;
    position: relative;
    margin-top: -6rem!important;
    margin-left: auto;
    margin-right:auto;
}


.timeline-2{
    margin-top: -6rem;
    position: absolute;
}
.video-play-button-2{
    width:36px;
    bottom: 20px;
    right: 20px;

}

 @media screen and (min-width:960px){

    .golinelli-section-2{
    padding-top:5rem;
    padding-bottom:5rem;
    }

    .video-story-2{
    width: 300px;
    }

    .golinelli-section-3{

    height: 640px;
    }

    .video-story-2{

    margin-left: 3rem;
}

 }


/*
SECTION 4
*/

 .golinelli-section-4{

    min-height: 640px;
}

.golinelli-standing{
    z-index: 2;
position: relative;
}


/*
SECTION 5
*/

.golinelli-section-5{
    background-color:#d7395d;
    height:450px;
}

 .edge-top-3{
    height: 25px;
    top: -10px;
}

.edge-bottom-3{
    bottom: 0px;
}


 .video-story-3{
    width: 80%;

position: relative;
margin-left: auto;
margin-right: auto;
}

 .golinelli-section-6,.golinelli-section-8{
    position: relative;
    min-height:640px;
}

.sketch-founds-container{
    color:#2E408F;
    margin-top:1rem;
}

.logo-sketch{
    position:absolute;
    right:0;
    bottom:0;
}

.golinelli-founds{
    position:absolute;
    bottom:50px;
}

.timeline-3{
    position: relative;
}

 @media screen and (min-width:960px){

    .golinelli-section-5{
        height:640px;
    }

    .video-story-3{
        width: 300px;
    position: absolute;
    top: 0;
    LEFT: 18%;
    }

    .golinelli-section-6 .col-md-6{

    min-height:500px;
    }

    .sketch-founds-container{
    margin-top:5rem;
    }

     .golinelli-founds{
    width:auto;
    bottom:50px;
}
 }

/*
SECTION 7
*/

.golinelli-section-7{
    position: relative;
    height:450px;
    background-color:#fdcd4b;
}

.edge-top-4{
    height: -5px;
    top: -10px;
}

.edge-bottom-4{
    bottom: 0px;
}

.star-vector{
    position: absolute;
}
.timeline-4{
    /*margin-left: 6rem;*/
    margin-top: 2rem;
    position: relative;
z-index: 3;

}

.timeline-4 .timeline-4-img{
    margin-left:auto;
margin-right: auto;
}



.video-story-4{
    width: 80%;
    position: relative;
    margin-left:auto;
    margin-right:auto;
}

.video-story-4-1{
    width: 36%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 550px;
    left: 55%;
}


 @media screen and (min-width:960px){
    .golinelli-section-7{
        height:640px;
    }

    .video-story-4{
    width: 300px;
    position: absolute;
    top: 0;
    right: 60px;
}

    .video-story-4-1{

        width: 400px;
        position: absolute;
        top: 670px;
        left: 55%;

    }
 }

/*
SECTION 9
*/
 .golinelli-section-9{
    position: relative;
    background-color:#7ec0e6;
}

.edge-top-5{
    height: -5px;
    top: -10px;
}

.edge-bottom-5{
    bottom: 0px;
}

.video-story-5{
    position: relative;
    }

.golinelli-section-10 h1{
    color:#7ec0e6;
    margin-bottom:2rem;
}

.golinelli-section-10 ul{
    list-style: none;
    padding-left: 0;
}

.golinelli-section-10 ul li{
    padding-bottom:10px;
}

.marino-paola{
    width: 100%;
}

