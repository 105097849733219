article.news{
    background:#F4F4F4;
}

article.news .col-md-6{
    min-height: 300px;
}

article.news .news-anchor{
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

article.news img{
    width:100%;
}

article.home-news{
    position: relative;
}

article.home-news:before{
    position: absolute;
    content: "";
    width: 380px;
    height: 58px;
    top: -50px;
    right: 0;
    display: none;
}

@media(max-width:767px){
    article.home-news:before{
        display: block;

    }
}

article p{
    font-size: .9rem;
}


.rassegna-list a,.comunicati-list a{
    display: block;
    background:#F4F4F4;
    margin: 1rem 0;
    padding: 1rem;
}

.rassegna-list .rassegna-date, .rassegna-list .rassegna-testata {
    color: #4A4A4A;
    font-size: 0.8125rem;
}

.rassegna-list .rassegna-testata{
    float:right;
}

.news-main__image{
    padding-left: 0;
    min-height:312px;
}
.news-main__image img{
    width:100%;
}

.news-main h2{
    font-size: 1.5rem;
}

.news-content{
    padding:1.5rem;
}

@media (min-width:992px){

    .news-content{
        padding-right:15px;
        padding-left:15px;
    }
}


/* carousel */

.carousel-item article.news{
    min-height: 360px;
}

#homeCarousel{
    height: 800px;
}

#homeCarousel .news-image{
        height:360px;
        background-size: cover;
}

#homeCarousel .news-content{
    height:360px;
}

#homeCarousel .carousel-control-next, #homeCarousel .carousel-control-prev{
    width:5%;
}

#homeCarousel article.news .news-anchor{
    right: 3rem;
}

#homeCarousel article.news h3{
    font-size: 1.4rem;
}


@media (min-width:992px){
    #homeCarousel {
        height: 360px;
    }

    #homeCarousel .news-image{
        height:360px;
    }

    #homeCarousel .news-content{
        height:360px;
    }

    #homeCarousel article.news h3{
        font-size: 1.8rem;
    }
}

