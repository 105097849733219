/*
JUMBOTRON
*/

.jumbotron{
    min-height: 500px;
}
.jumbotron p{
    font-family: 'Asap Condensed';
}

.jumbotron-over{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
    z-index: 0;
    top: 0;
}
.jumbotron-content{
    position: relative;
}

.jumbotron.bg-cover {
    background-size: cover;
    background-position: center center;
    position: relative;
  }

.jumbotron h1{
    font-size: 2.1rem;
    text-transform: uppercase;
    color:#fff;
    text-shadow: 2px 2px black;
}
.jumbotron p{
    color: #fff;
    font-size: 1.3rem;
}

.btn-home{
    color: rgba(255,255,255,.8);
    font-size: 5rem;
    display: block;

}

.btn-home:hover{
    color:#D21E47;


}

@media (min-width:1200px){
    .jumbotron h1{
        font-size: 3.1rem;

    }
}

/*
STATS
*/

.stats .stats-header{
    display: inline-block;
}
.stats .stats-label{
    font-size: 2.5rem;
    color: #686868;
    font-weight: 500;
    padding-left: .5rem;
    line-height: 2.3rem;
    font-family: 'Asap Condensed';
}

.stats .stats-text{
    text-transform: uppercase;
    font-size: 14px;
    color:#9B9B9B;
}

.stats-header img {
    height: 35px;
    display: block;
    margin: .5rem auto;
}

@media (min-width:1200px){
    .stats-header img {
        float:left;
        display: inline;
        margin: auto;
    }
}

/*
CLAIM
*/

.main-claim{
font-size: 1.5rem;
}

/*
BUILDING
*/

.building{
    width: 950px;
    position: relative;
    height: 450px;
    margin: 0 auto;
    margin-bottom: 6rem;
    display: none;
}
.building-block{
    position: absolute;
    width: 25%;
    height: 100%;

}

.building-block-image,.building-block-image-on{
    position: absolute;
    right: 0;
    width:100%;
}

.home-area-accordion .card{
    background: #f8f9fa;
    border:0;
    margin-bottom: 10px;
}

.home-area-accordion .card .card-header{
    background: transparent;
    border:0;
}
.home-area-accordion .card .card-header .btn{
    width: 100%;
    text-align: left;
    position: relative;
    background: #f8f9fa;
}




@media (min-width:992px){

    .building{

        display: block;
    }

    .building-block-1{
        right:0;

    }

    .building-block-2{
        right:25%;
    }

    .building-block-3{
        right: 75%;
    }

    .building-block-4{
        right:50%;
    }

    .building-block-3 .building-block-image,.building-block-3 .building-block-image-on{
        width:260px;
        top: 130px;
        right: -15px;
        z-index: 3;
    }

    .building-block-4 .building-block-image,.building-block-4 .building-block-image-on{
        width: 470px;
        top: 15px;
        right: -15px;
        z-index: 2;
    }

    .building-block-2 .building-block-image,.building-block-2 .building-block-image-on{
        top: 89px;
        right: -30px;
        width: 255px;
    }

    .building-block-1 .building-block-image,.building-block-1 .building-block-image-on{
        width: 235px;
        top: 87px;
        right: 25px;
    }

    .building-block-trees{
        position: absolute;
        right: 115px;
    }

    .building-block-people{
        position: absolute;
        right: 305px;
        top: 73px;
    }

    .building-en a.btn {
        font-size: 1rem;
    }

    .building-en .btn-ricerca{
        width: 330px !important;
    }


}

@media (min-width:1200px){
    .building-block .building-block-image, .building-block .building-block-image-on{
        width:auto;
    }



    .building-block-1 .building-block-image,.building-block-1 .building-block-image-on{
        top:78px;
        right: -40px;
    }




.building-block-2 .building-block-image,.building-block-2 .building-block-image-on{
    top:78px;
    right:-70px;
}




.building-block-3 .building-block-image,.building-block-3 .building-block-image-on{
    top: 130px;
    right: -15px;
}



.building-block-4 .building-block-image,.building-block-4 .building-block-image-on{
    top: 0px;
    right: -15px;

}

.building-block-trees{
    right: 115px;
}

.building-block-people{
    right: 305px;
    top: 73px;
}

}



.building-block .building-block-image-on{
    display: none;
}

.building-block:hover .building-block-image-on{
    display: block;
}

.building-block .btn{
    bottom: 0;
    position: absolute;
    width: 200px;
    font-weight: 500;
    left: 50%;
    margin-left: -100px;
}

.building-block .btn-image{
    margin-top: -2px;
}

.building-block a.btn{
    background-color: #F4F4F4;
    border-color:#F4F4F4;
}

.building-block a.btn.btn-arte{
   color: #FCC924;
}

.building-block a.btn.btn-scuola{
    color: #E82A97;
}



.building-block a.btn.btn-impresa{
    color: #409A46;
}


.building-block a.btn.btn-ricerca{
    color: #47A5DC;
}

.building-block:hover a.btn{
    color:#fff;
}

.building-block:hover a.btn.btn-arte{
    background-color: #FCC924;
}



.building-block:hover a.btn.btn-scuola{
    background-color: #E82A97;
}



.building-block:hover a.btn.btn-impresa{
    background-color: #409A46;
}


.building-block:hover a.btn.btn-ricerca{
    background-color: #47A5DC;
}

.building-block img.indicatore {
    bottom: 54px;
    position: absolute;
    left: 50%;
    margin-left:-12px;
}




/*
AREA INFO
*/

.area-info{
    /*background: #F4F4F4;*/
    background: #FFF;
    padding: 4rem 0;
    position: relative;
    display: none;
    background-position: center bottom;
}

.area-info .container{
    position: relative;
}

.area-info .container:before{
    content: '';
    position: absolute;
    left: calc(20%);
    top: -98px;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 35px solid #F4F4F8;
    clear: both;
}

.area-info h2{
    text-transform: uppercase;
    margin-bottom: .5rem;
    margin-top: 0;
}

.area-info-image img{
    width:100%;
}

.area-info p{

}

.area-info-content ul{
    list-style: none;
    padding: 0;
    padding-bottom: .5rem;
}

.area-info-content ul li{
    padding-bottom: .5rem;
}




.area-info-menu ul{
    margin: 0.75em 0;
    padding: 1rem 1rem 1rem 2rem;
    list-style: none;
    height: 195px;
}

.area-info a.btn{
    width:100%;
    color:#fff;
}

.area-info-scuola .area-info-menu ul li:before{
    border-color: transparent #E82A97;
}

.area-info-scuola b{
    color:#E82A97;
}



.area-info-arte .area-info-menu ul li:before{
    border-color: transparent #FCC924;
}

.area-info-impresa .area-info-menu ul li:before{
    border-color: transparent #409A46;
}

.area-info-ricerca .area-info-menu ul li:before{
    border-color: transparent #47A5DC;
}

.area-info-menu ul li:before {
    content: "";
    border-style: solid;
    border-width: 0.30em 0 0.30em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1em;
    position: relative;
}


.area-info-menu ul li a{
    margin-bottom: 5px;
    font-size: .875rem;
}

.area-info ul li a{
    color:#4A4A4A;
}


/* aree */


.area-info-scuola .container::before{
    left: 37%;
}

.area-info-arte .container::before{
    left: 16%;
}

.area-info-impresa .container::before{
    left: 58%;
}

.area-info-ricerca .container::before{
    left: 79%;
}




/*
ACCORDION
*/
@media (min-width:992px){
    .home-area-accordion{
        display:none;
    }
}

/*
FONDAZIONE
*/
.fondazione h3{
    position: relative;
}
/*.fondazione h3:after{
    content: " ";
    height: 2px;
    width: 40px;
    background: #4A4A4A;
    position: absolute;
    bottom: -33px;
    left: 0;
    display: block;
    color: #4A4A4A;
    margin-left: 0;
}*/

.fondazione p{
    font-size: 1.1rem;
}

.fondazione .short-text{
    height: 140px;
}

.fondazione .short-text:after{
    top: 94px;
}
